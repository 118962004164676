import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// ReactGA.initialize('UA-8870627-15');
ReactGA.initialize('G-RE4WG6VN51');
ReactGA.send("pageview");

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

