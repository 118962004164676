import React from 'react';
import Image from 'react-bootstrap/Image'

import './pages.css';

class BlackBerry extends React.Component {

  componentDidMount() {
    // console.log('BlackBerry: componentDidMount()');
    document.title = 'BlackBerry: John Wargo Books';
  }

  render(){
    return (
      <div className='container'>
        <a href="https://amzn.to/3xaw60f" target="_blank" rel="noreferrer"><Image src="/images/cover-bbdf-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h1>BlackBerry Development Fundamentals</h1>
        <p>For several years, I worked at Research In Motion (RIM, later renamed BlackBerry) as an evangelist working with
          RIM's largest carrier and its customers, helping them learn how to build mobile applications for the BlackBerry
          platform. During this time, I recognized that while the BlackBerry development documentation was helpful,
          it was a tough starting point for beginning BlackBerry developers. Once I left RIM, I decided to write a
          book for beginning BlackBerry developers, which ended up being the first book dedicated to BlackBerry development.</p>
        <p>BlackBerry Development Fundamentals was my first technical book and a lot of fun to write. The book is
          an introductory book on BlackBerry Development; at the time, the only book of its kind.</p>
        <p>It explains in detail everything you need to develop applications for the BlackBerry platform. It covers
          the server components (BlackBerry Mobile Data System), each of the possible development options for
          the platform, how to install and operate RIM's free development plus how to deploy applications.
        </p>
        <p>It's not a heavy code book nor is it a book on Java development for BlackBerry, there are other books
          out there that cover that well. It's a book that is designed to help new or existing BlackBerry developers
          get all of the fundamental skills they need to get started developing applications for BlackBerry.</p>
        <p>It explains what you can and cannot do with mobile applications for BlackBerry, the platforms strengths
          and weaknesses (from a development standpoint) and contains many tricks that will help save BlackBerry
          developers lots of time when building their applications.</p>
        <p>
          <a href="https://amzn.to/3xaw60f" target="_blank" rel="noreferrer">
            Visit the Amazon page for this book.
          </a>
        </p>
      </div>
    );
  }
  
}

export default BlackBerry