// TODO: Add URL to this list?
export const publicationList = [
    "Advisor Expert on Lotus Notes & Domino R5 Magazine",
    "Dr. Dobb's Journal",
    "e-Pro Magazine",
    "HackSpace Magazine",
    "InformIT",
    "Make Magazine",
    "Medium",
    "The MagPi Magazine",
    "The View Magazine",
    "PhoneGap Blog"
];

// An empty Article
// ,{
//     "title": "",
//     "url": "",
//     "format": 1,
//     "description": "",
//     "publication": "",
//     "pubMonth": ,
//     "pubYear": 2021
// }

export const articleList = [
    {
        "title": "Make the Switch",
        "url": "https://makezine.com/go/electronic-relays",
        "format": 0,
        "description": "I've always been fascinated with relays and I love putting them into projects. Whenever I found a relay board for a particular single board computer or microcontroller, I bought it and figured out how to use it. This article came from a proposal I made to Make Magazine about a general article that covered all sorts of relays and relay boards. For the article cover photo, I sent all of my relays and boards to California and the Make team did a professional photo shoot of them all lumped together.",
        "publication": "Make Magazine",
        "pubMonth": 2,
        "pubYear": 2018
    },
    {
        "title": "Arduino Programming: Variables, constants, and more",
        "url": "https://hackspace.raspberrypi.org/issues/4",
        "format": 0,
        "description": "This was my last article for Hackspace Magazine; an introduction to variables, constants, and defines on Arduino. ",
        "publication": "Hackspace Magazine",
        "pubMonth": 3,
        "pubYear": 2018
    },
    {
        "title": "Arduino Programming: Reading Data From Devices",
        "url": "https://hackspace.raspberrypi.org/issues/3",
        "format": 0,
        "description": "This article covered measuring information on an Arduino device using simple digital devices and use the data to control hardware - in this case, turning on an LED when the temperature crosses a threshold.",
        "publication": "HackSpace Magazine",
        "pubMonth": 2,
        "pubYear": 2018
    },
    {
        "title": "Reading digital data on the Arduino platform",
        "url": "https://hackspace.raspberrypi.org/issues/2",
        "format": 0,
        "description": "This article highlighted how to read external data on an Arduino, in this case reading the state of a button.",
        "publication": "HackSpace Magazine",
        "pubMonth": 1,
        "pubYear": 2018
    },
    {
        "title": "Add Arduino power to your projects",
        "url": "https://hackspace.raspberrypi.org/issues/1",
        "format": 0,
        "description": "An introduction to Arduino development.",
        "publication": "HackSpace Magazine",
        "pubMonth": 12,
        "pubYear": 2017
    },
    {
        "title": "Pi Zero Visual Appointment Reminder",
        "url": "https://www.raspberrypi.org/magpi/pi-zero-calendar/",
        "format": 0,
        "description": "For this project, I took my Pi Remind and modified it for the Pi Zero device (and a much smaller LED array - the Pimoroni Blinkt!). For some reason, the original article was deleted and this one is a reprint under another author's name (with original credit to me).",
        "publication": "The MagPi Magazine",
        "pubMonth": 6,
        "pubYear": 2017
    },
    {
        "title": "Solder Together a Fairy Lights Mason Jar",
        "url": "http://makezine.com/projects/mason-jar-tricolor-twinkle-lights/",
        "format": 0,
        "description": "This article shows how to make a July 4th themed (US Independence Holiday) Fairy lights in a mason jar. For this, I used three strands of LEDS (Red, White, and Blue - of course) and a little Arduino code to fade the different strands up and down.",
        "publication": "Make Magazine",
        "pubMonth": 6,
        "pubYear": 2017
    },
    {
        "title": "Collect and Display Weather Data with a Raspberry Pi",
        "url": "http://makezine.com/projects/raspberry-pi-weather-station-mount/",
        "format": 0,
        "description": "I've always been interested in hosting my own weather station, but I've never been interested in plunking down the cash to buy one of the professional stations. For this article, I wired up a Raspberry Pi device and the Astro Pi Sense HAT to build a little inexpensive weather station that posts weather data to Weather Underground.",
        "publication": "Make Magazine",
        "pubMonth": 11,
        "pubYear": 2016
    },
    {
        "title": "Get a Flashing Meeting Reminder with a Raspberry Pi",
        "url": "http://makezine.com/projects/get-a-flashing-meeting-reminder-with-a-raspberry-pi/",
        "format": 0,
        "description": "At the time I wrote this, I worked at home (I still do) and I spent a lot of time on the phone talking to colleagues, vendors and customers. My employer used Google Calendar and Gmail but the appointment notification capabilities were not very robust. Well, they’re robust, but not enough to get me to actually show up for calls on time. I often missed  notifications when I’m busy doing something else or I notice the notification, but quickly forget about it just a few minutes later and ultimately miss some meetings or join late. I built the project described in this article to give me a visual reminder when I have appointments. Its 2021 and I still use it every day.",
        "publication": "Make Magazine",
        "pubMonth": 6,
        "pubYear": 2016
    },
    {
        "title": "Apache Cordova and Server-Side Technologies",
        "url": "http://www.informit.com/articles/article.aspx?p=2481899",
        "format": 1,
        "description": "I saw a lot of Stack Overflow questions asking about how to attach an Apache Corodva application to different server-side technologies, so I decided to write an article talking about the different options.",
        "publication": "InformIT",
        "pubMonth": 2,
        "pubYear": 2016
    },
    {
        "title": "What's New in Apache Cordova 4?",
        "url": "http://www.informit.com/articles/article.aspx?p=2347269",
        "format": 1,
        "description": "An introduction to Apache Cordova 4.",
        "publication": "InformIT",
        "pubMonth": 4,
        "pubYear": 2015
    },
    {
        "title": "Arduino-Powered Pinewood Derby Race Car",
        "url": "http://makezine.com/projects/arduino-powered-pinewood-derby-race-car/",
        "format": 0,
        "description": "At the time, my son was in Cub Scouts, and for many years built Pinewood Derby cars. For the most part, I let my son do what he wanted to do with his car but after watching one parent spend hours painstakingly cutting, sanding, and grinding his kid’s car, my son and I decided we’d kick it up a notch. I built an Arduino-powered Pinewood Derby car with an acceleromter and LEDs. The car flashes the lights differently depending on the angle of the car.",
        "publication": "Make Magazine",
        "pubMonth": 3,
        "pubYear": 2015
    },
    {
        "title": "Comparing Approaches to Mobile Development",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 2,
        "pubYear": 2013
    },
    {
        "title": "Mobilizing Domino Data Using Sencha Touch, Part 3",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 5,
        "pubYear": 2013
    },
    {
        "title": "Mobilizing Domino Data Using Sencha Touch, Part 2",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 12,
        "pubYear": 2012
    },
    {
        "title": "Mobilizing Domino Data Using Sencha Touch, Part 1",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 11,
        "pubYear": 2012
    },
    {
        "title": "Mobilizing Domino Data Using Rhodes and RhoConnect, Part 4",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 8,
        "pubYear": 2012
    },
    {
        "title": "Mobilizing Domino Data Using Rhodes and RhoConnect, Part 3",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 7,
        "pubYear": 2012
    },
    {
        "title": "Mobilizing Domino Data Using Rhodes and RhoConnect, Part 2",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 5,
        "pubYear": 2012
    },
    {
        "title": "Mobilizing Domino Data Using Rhodes and RhoConnect, Part 1",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 5,
        "pubYear": 2012
    },
    {
        "title": "Mobilize Your Enterprise Data Using a Mobile Application Platform",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 3,
        "pubYear": 2012
    },
    {
        "title": "Mobilizing Domino Using Appcelerator Titanium",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 10,
        "pubYear": 2011
    },
    {
        "title": "Preparing Your Domino Developer Brain (and Applications) for Mobile",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 8,
        "pubYear": 2011
    },
    {
        "title": "Mobilizing Domino Applications for Multiple Mobile Device Platforms Using PhoneGap",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 7,
        "pubYear": 2011
    },
    {
        "title": "Mobilizing Domino Applications Using Teamstudio Unplugged",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 6,
        "pubYear": 2011
    },
    {
        "title": "Creating Domino-Friendly Entries in Google Analytics",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 3,
        "pubYear": 2011
    },
    {
        "title": "Mobilize Notes Applications to iPhone and iPad Using Apple Dashcode",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 3,
        "pubYear": 2011
    },
    {
        "title": "Mobilizing Domino Data Using Kryos Velocity AppXtender",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 1,
        "pubYear": 2011
    },
    {
        "title": "BlackBerry DevCon 2010: A Developer's Perspective",
        "url": "http://drdobbs.com/mobility/227700350",
        "format": 1,
        "description": "Aaah, my only article in Dr. Dobbs. I used to love reading that magazine. The title should descibe the article pretty well.",
        "publication": "Dr. Dobb's Journal",
        "pubMonth": 10,
        "pubYear": 2010
    },
    {
        "title": "Taking it With You, Part 2: Mobilizing Domino Databases Using Native Clients, Hybrid Applications, and MEAPs",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 10,
        "pubYear": 2010
    },
    {
        "title": "Taking It With You, Part 1: Know Your Options for Mobilizing Domino Databases via the Web",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 9,
        "pubYear": 2010
    },
    {
        "title": "Mobile Application Development Trends — What Today's Developer Needs to Know Now",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 6,
        "pubYear": 2010
    },
    {
        "title": "BlackBerry Developer's Conference Wrap-Up",
        "url": "https://www.informit.com/articles/article.aspx?p=1582938",
        "format": 1,
        "description": "",
        "publication": "InformIT",
        "pubMonth": 5,
        "pubYear": 2010
    },
    {
        "title": "BlackBerry Widgets — A Fast, Easy Way to Mobilize Your Domino Applications",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "The View Magazine",
        "pubMonth": 3,
        "pubYear": 2010
    },
    {
        "title": "Best Practices in Notes Client Deployment (with David Via)",
        "url": "",
        "format": 2,
        "description": "David and I worked together for many, many years and we built a methodology (David) and tooling (me) for managing the process of deploying the IBM Lotus Notes client (now called HCL Notes). In this article, we shared our knowledge with the community.",
        "publication": "e-Pro Magazine",
        "pubMonth": 10,
        "pubYear": 2003
    },
    {
        "title": "Manipulate Notes Preference Settings",
        "url": "",
        "format": 2,
        "description": "",
        "publication": "Advisor Expert on Lotus Notes & Domino R5 Magazine",
        "pubMonth": 5,
        "pubYear": 2002
    }, {
        "title": "Which to Use: Cordova or PhoneGap?",
        "url": "https://www.informit.com/articles/article.aspx?p=2478076",
        "format": 1,
        "description": "I found a reason to refer to this article just last week (July, 2021) because apparently people still don't understand the difference between Apache Cordova and Adobe PhoneGap.",
        "publication": "InformIT",
        "pubMonth": 2,
        "pubYear": 2016
    }, {
        "title": "A Better Way to Exercise Cordova Plugins",
        "url": "https://cordova.apache.org/howto/2018/02/02/cordova-simulate.html",
        "format": 1,
        "description": "This was my first (and only) PhoneGap blog post, describing how to build support into your Apache Cordova Plugins for the Cordova Simulate plugin simulator from Microsoft. This was also the topic for my presentation at the very last US PhoneGap day (in 2017).",
        "publication": "PhoneGap Blog",
        "pubMonth": 2,
        "pubYear": 2018
    }, {
        "title": "It’s Almost Impossible to Get Real Developer Support Anymore",
        "url": "https://johnwargo.medium.com/it-is-almost-impossible-to-get-developer-support-anymore-5c2a23e3feaa",
        "format": 1,
        "description": "At the time I wrote this, I was doing a lot of cloud development and needed help with some issues I was having. At the time I was very frustrated with how the cloud service providers pushed support out to the community even though I was paying for the services I used. The article promotes my view of how this should work.",
        "publication": "Medium",
        "pubMonth": 3,
        "pubYear": 2021
    }, {
        "title": "Submitting Questions on Stack Overflow as a Last Resort",
        "url": "https://johnwargo.medium.com/submitting-questions-on-stack-overflow-as-last-resort-8219a710f744",
        "format": 1,
        "description": "In trying to get some help with some development issues, I either received no response to my questions, or Stack Overflow actively deleted my posts for a variety of ridiculous reasons. I wrote this article to share my views on the situation.",
        "publication": "Medium",
        "pubMonth": 4,
        "pubYear": 2021
    }, {
        "title": "Industry Analysts Must Get Their Facts Right",
        "url": "https://johnwargo.medium.com/industry-analysts-must-get-their-facts-right-4e1c67bfc35a",
        "format": 1,
        "description": "I read a recent Gartner report and noticed that they published some deliberately misleading and incorrect information in their argument about a particular vendor's product. A later read showed that the repeated the falsehood repeatedly throughout the publication. I wrote this article to highlight the error and, of course, prove why their statements were incorrect.",
        "publication": "Medium",
        "pubMonth": 8,
        "pubYear": 2021
    }, {
        "title": "But I Don’t Know Dart",
        "url": "https://johnwargo.medium.com/but-i-dont-know-dart-c1b4e2380191",
        "format": 1,
        "description": "I did a lot of research this year into Flutter and React Native and every time I mentioned Flutter to a developer, they'd immediately respond that they weren't interested in using Flutter because that meant they'd have to learn Dart (the launguage Flutter uses). I wrote the article to highlight that I've been an active Flutter developer for more than a year now and I really don't 'know' Dart.",
        "publication": "Medium",
        "pubMonth": 8,
        "pubYear": 2021
    }
];