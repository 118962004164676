import React from 'react';
import Image from 'react-bootstrap/Image'

import './pages.css';

class Soccer extends React.Component {

  componentDidMount() {
    // console.log('Soccer: componentDidMount()');
    document.title = 'Soccer: John Wargo Books';
  }

  render() {
    return (
      <div className='container'>
        <a href="https://amzn.to/3hnEfYH" target="_blank" rel="noreferrer"><Image src="/images/cover-what-now-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h1>What Now? The Essential Guide for New Soccer Referees</h1>
        <p><em>What Now? The Essential Guide for New Soccer Referees</em> was my first book. I decided to write it as I
          retired from Soccer officiating.</p>
        <p>Having been a US Soccer State Referee Instructor for many years, I had a lot of experience working with brand new referees. I knew that in the rush to get information on the Soccer Laws of the Game into the brains of the new Soccer Referee, that only a small amount of time is spent at the end of the standard US Soccer New Referee Clinic teaching new Referees how to actually be Referees. I knew what I had to do - I had to write a book that was designed to supplement the New Referee Clinic material.</p>
        <p>The purpose of the book then is to cover everything the new Referee needs to know and do between the end of their first Referee clinic and the beginning of their second game. There's nothing in the book about the Laws of the Game, but a whole lot of detailed information on:</p>
        <ul>
          <li>How to put together the Referee's kit (the uniform, bag, flags and everything else a new Referee needs to
            have with them)
          </li>
          <li>How to prepare for a game</li>
          <li>How to self-assess after a game</li>
          <li>How to inspect the field</li>
          <li>How to blow the whistle (something many new Soccer Referees struggle with)</li>
          <li>What to do before the game</li>
          <li>What to do at half time</li>
          <li>What to do after the game</li>
          <li>and much, much more</li>
        </ul>
        <p>
          <a href="https://amzn.to/3hnEfYH" target="_blank" rel="noreferrer">
            Visit the Amazon page for this book.
          </a>
        </p>
      </div>
    );
  }
}

export default Soccer;
