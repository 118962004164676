import React from 'react';

import { Article } from '../article';
import { articleList, publicationList } from '../data';

import './pages.css';
import './articles.css';

type ArticleProps = {

}

type ArticleState = {
  publications: String[],
  articles: Article[]
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"];

const pubTypes = ["Online & Print", "Online Only", "Print Only"];

class Articles extends React.Component<ArticleProps, ArticleState> {

  constructor(props: ArticleProps) {
    super(props);
    this.state = {
      articles: articleList.sort(this.compare),
      publications: publicationList.sort()
    };
  }

  componentDidMount() {
    // console.log('Articles: componentDidMount()');
    document.title = 'Articles: John Wargo Books';
  }

  compare(a: Article, b: Article) {
    // https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/    
    var dateA: Date = new Date();
    dateA.setFullYear(a.pubYear);
    dateA.setMonth(a.pubMonth);

    var dateB: Date = new Date();
    dateB.setFullYear(b.pubYear);
    dateB.setMonth(b.pubMonth);

    let comparison = 0;
    if (dateA < dateB) {
      comparison = 1;
    } else if (dateA > dateB) {
      comparison = -1;
    }
    return comparison;
  }

  render() {
    if (this.state.articles.length > 0) {
      return (
        <div className='container articles'>
          <h1>Magazine and Online Articles</h1>
          <p>The following is a list of magazine and online articles I've published over the years. Unfortunately, many of the articles were in print publications and never made it online, or they made it online but the magazine since closed their doors (and shuttered their online presence).</p>
          {this.state.articles.map(function (article, idx) {
            var introText = article.url
              ? <strong><a href={article.url} target="_blank" rel="noreferrer">{article.title}</a></strong>
              : <strong>{article.title}</strong>
            return <div key={idx}>
              <p className='title'>{introText}</p>
              <p className='pubDetails'><em>{article.publication}</em>: {monthNames[article.pubMonth - 1]}, {article.pubYear}</p>
              <p className='summary'>{article.description}</p>
              <p className='formats'><strong>Format:</strong>&nbsp;{pubTypes[article.format]}</p>
            </div>;
          })}
        </div>
      );
    } else {
      return (
        <div className='container'>
          <h1>Article Data Error</h1>
          <p>No article data to render (surprisingly).</p>
        </div>
      );
    }
  }
}

export default Articles;
