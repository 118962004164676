import React from 'react';
import Image from 'react-bootstrap/Image'

import './pages.css';

class Home extends React.Component {

  componentDidMount() {
    // console.log('Home: componentDidMount()');
    document.title = 'John Wargo Books';
  }

  render() {
    return (
      <div className='container'>
        <div className="page-header">
          <Image src="/images/wargo_head_shot_cropped_240.png" alt="John Wargo Headshot" rounded />
          <h1>John M. Wargo</h1>
          <h4>Product Manager, software developer, writer, presenter, tinkerer, father, husband, and geek!</h4>
        </div>
        <div>
          <p className="lead">
            Welcome to John Wargo Books; on this site I list the books and magazine articles I've written and published over the years. To date, I've written 7 books, plus a series of articles I wrote for a developer magazine were later published into an anthology (making it a total of 8 books). Most of my books cover software development topics, but my very first book was, surprisingly, a book for new Soccer Officials.</p>
          <p><strong>My latest book is <em>Learning Progressive Web Apps</em>:</strong></p>
          <a href="https://amzn.to/365Y2qu" target="_blank" rel="noreferrer">
            <Image
              src="/images/cover-learning-pwa-160.png"
              alt="Learning Progressive Web Apps book cover"
              style={{ paddingBottom: "10px" }} />
          </a>
          <p>Use the menu at the top of the page to view details for all of my publications.</p>
          <p>
            I semi-regularly post articles on a wide range of random topics on my personal blog at&nbsp;
            <a href="http://www.johnwargo.com" target="_blank" rel="noreferrer">www.johnwargo.com</a>. You can also read more about all of my books on my&nbsp;
            <a href="http://amzn.to/2crgPTJ" target="_blank" rel="noreferrer">Amazon Author Page</a>.
          </p>
          <hr />
          <p>You can also find me online elsewhere at: </p>
          <a href="http://www.johnwargo.com/" target="_blank" rel="noreferrer">
            <img src="/images/jmw.png" alt="JMW Avatar" className="logo-link" />
          </a>
          <a href="https://github.com/johnwargo" target="_blank" rel="noreferrer">
            <img src="/images/logo-github.png" alt="GitHub logo" className="logo-link" />
          </a>
          <a href="http://www.twitter.com/johnwargo/" target="_blank" rel="noreferrer">
            <img src="/images/logo-twitter.png" alt="Twitter Logo" className="logo-link" />
          </a>
          <a href="https://www.linkedin.com/in/jmwargo" target="_blank" rel="noreferrer">
            <img src="/images/logo-linkedin.png" alt="LinkedIn Logo" className="logo-link" />
          </a>
          <a href="https://johnwargo.medium.com/" target="_blank" rel="noreferrer">
            <img src="/images/logo-medium.png" alt="Medium Logo" className="logo-link" />
          </a>
          <a href="http://amzn.to/2crgPTJ" target="_blank" rel="noreferrer">
            <img src="/images/logo-amazon.png" alt="Amazon Logo" className="logo-link" />
          </a>
        </div>
      </div>
    );
  }
}

export default Home;