import React from 'react';
import Image from 'react-bootstrap/Image'

import './pages.css';

class Python extends React.Component {

  componentDidMount() {
    // console.log('Python: componentDidMount()');
    document.title = 'Python: John Wargo Books';
  }

  render() {
    return (
      <div className='container'>
        <h1>Technical Reviewer: Python Books</h1>
        <p >I've recently started performing technical reviews for several of Pearson's Python products. I didn't write or
          record these products, but I am listed in the credits.</p>
        <a href="https://amzn.to/3jD4Of0" target="_blank" rel="noreferrer"><Image src="/images/cover-python-without-fear-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h2>Python Without Fear</h2>
        <p>Learning Python Doesn’t Have to Be Difficult!</p>
        <p>Have you ever wanted to learn programming? Have you ever wanted to learn the flexible, easy Python language behind many of today’s best web, data analytics, and scientific applications? Whether you’re new to programming or moving from another language, Python Without Fear will quickly make you productive with Python!</p>
        <p>Brian Overland has earned rave reviews for his unique approach to teaching programming. He starts with short, simple examples you can easily enter and run. Within a couple of chapters, you’ll be creating useful utilities, playing games, and solving puzzles. Everything is simplified to its essentials, patiently explained, and clearly illustrated with practical examples and exercises that help you make progress quickly.</p>
        <p>Overland reveals the “whys” and “tricks” behind each Python language feature. And you’ll never get bogged down in complex or pointless examples: He keeps you 100% focused on learning what works and what matters—while having fun!</p>
        <ul>
          <li>Covers installing and using the newest Python 3.x versions, and shows how to adapt your code for older versions
          </li>
          <li>Teaches with puzzles, games, and relevant exercises</li>
          <li>Offers more “why” and “how-to” coverage—reveals how Python works “under the covers,” so you can avoid time-consuming
            mistakes
          </li>
          <li>Takes you from basic scripting to advanced objects and data management</li>
          <li>Contains an expanded reference section for all your day-to-day programming</li>
          <li>Well-designed for programmers who’ve previously used C, C++, Visual Basic, or Java</li>
        </ul>
        <p>Whether you want to learn Python programming for pleasure or you’re considering a career in programming, this
          book is an outstanding choice.</p>
        <p>
          <a href="https://amzn.to/3jD4Of0" target="_blank" rel="noreferrer">
            Visit the Amazon page for this book.
          </a>
        </p>

        <a href="https://amzn.to/3jD4Of0" target="_blank" rel="noreferrer"><Image src="/images/cover-python-scratch-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h2>Python from Scratch</h2>
        <p>Python from Scratch LiveLessons is a gentler, more entertaining, and more practical starting point to learning the Python programming language. Unlike the typical approach to teaching a programming language that covers each feature section by section, this course is structured more like a cooking class. You can follow along and execute all the code being demonstrated in an interactive notebook as you watch the video, much the same way you would watch a cooking video in your kitchen.</p>
        <p>Beginner level for anyone new to Python and object-oriented programming. Builds from scratch from beginner to more advanced topics through a follow-along approach.</p>
        <p>What You Will Learn:</p>
        <ul>
          <li>How to use the interactive Jupyter notebook platform to execute simple commands</li>
          <li>How to use the import statement to use external code and read input from an external source</li>
          <li>How to work with strings and how to use indexing operators</li>
          <li>How to write program controls</li>
          <li>How to use built-in types and write/use user-defined types</li>
          <li>How to access elements in dictionaries</li>
          <li>How to use if and elif control structures</li>
          <li>How to take advantage of dictionaries and tuples</li>
          <li>How to write complex functions</li>
          <li>How to use keyword arguments in functions</li>
          <li>How to write classes and use inheritance</li>
          <li>How to read data in and push data out to other programs</li>
          <li>How to create callbacks and mock classes</li>
          <li>How to use third-party libraries</li>
          <li>How to document Python code</li>
        </ul>
        <p>
          <a href="https://www.safaribooksonline.com/library/view/python-from-scratch/9780134745923/" target="_blank" rel="noreferrer">
            Visit the Safari page for this Video Series.
          </a>
        </p>
      </div>
    );
  }
}

export default Python;