import React from 'react';
import Image from 'react-bootstrap/Image'

import './pages.css';

class Cordova extends React.Component {

  componentDidMount() {
    // console.log('Cordova: componentDidMount()');
    document.title = 'Apache Cordova: John Wargo Books';
  }

  render() {
    return (
      <div className='container'>
        <h1>Apache Cordova & PhoneGap Books</h1>
        <p>I contributed to the Apache Cordova project for many years, and during that time I published several books on the topic.</p>

        <a href="https://amzn.to/2Ug9XPw" target="_blank" rel="noreferrer"><Image src="/images/cover-ac4p-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h2>Apache Cordova 4 Programming</h2>
        <p>This book is a complete rewrite of <em>Apache Cordova 3 Programming</em>, updated for Apache Cordova 4. This book also dramatically expanded the depth of topic coverage from the previous edition, delivering many more details on how to use the framework for cross-platform mobile app development. It weighs in at about 200 pages longer than the original <em>PhoneGap Essentials</em>.</p>
        <p>Coupled with <em>Apache Cordova API Cookbook</em>, the two volumes deliver almost 800 pages of materials; providing
          the most complete coverage of Apache Cordova available anywhere.</p>
        <p>Here's the description from the book's back cover:</p>
        <p>Using Apache Cordova 4, you can leverage native technologies and web standards to quickly build cross-platform
          apps for any mobile device. You can deliver a high-end user experience where it matters, while radically
          simplifying code maintenance and reuse. Apache Cordova 4 Programming is the most concise, accessible
          introduction to this remarkable technology.</p>
        <p>In this essential guide, expert mobile developer John Wargo quickly gets you up-to-speed with all the
          essentials, from installation and configuration to building plugins and using Cordova’s powerful
          APIs. Wargo helps you make the most of Cordova 4’s major enhancements, while offering practical guidance
          for all versions, including Adobe PhoneGap. Full chapters are dedicated to five major mobile platforms:
          Android, iOS, Windows Phone, Firefox OS, and Ubuntu.</p>
        <p>Using rich, relevant examples, Wargo illuminates both the anatomy of a Cordova app and its entire lifecycle,
          including cross-platform testing and debugging. Throughout, he illuminates Cordova development best
          practices, helping you write high-quality code right from the start.</p>
        <p>Topics include</p>
        <ul>
          <li>Installing and configuring Cordova’s development environment</li>
          <li>Working with the Cordova command line interface</li>
          <li>Creating Cordova plugins, using Plugman and the PhoneGap CLI</li>
          <li>Cordova’s support for Firefox OS and Ubuntu devices</li>
          <li>Automation (Grunt and Gulp) and Cordova CLI hooks</li>
          <li>Microsoft’s hybrid toolkit for Visual Studio</li>
          <li>Third-party tools such as AppGyver, GapDebug, THyM, and more</li>
          <li>Extending Cordova with third-party HTML frameworks such as Bootstrap, OpenUI5, Ionic, and Onsen UI
          </li>
          <li>Running, testing, and debugging Cordova apps on each major mobile platform</li>
        </ul>
        <p>
          <a href="http://cordovacookbook.com" target="_blank" rel="noreferrer">
            Visit the book's web site.
          </a>
          &nbsp;
          <a href="https://amzn.to/2Ug9XPw" target="_blank" rel="noreferrer">
            Visit the Amazon page for this book.
          </a>
        </p>

        <a href="https://amzn.to/3jxlnJA" target="_blank" rel="noreferrer"><Image src="/images/cover-acac-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h2>Apache Cordova API Cookbook</h2>
        <p>Each of my other Cordova books allocated equal space to the Cordova tooling and the Cordova APIs. For
          this volume, I delivered an overview and detailed implementation details for each of the Cordova
          APIs. The book can stand alone in that it contains a brief introduction to Cordova at the start,
          followed by the details for each API. The book includes at least one, but usually more, complete,
          functional applications demonstrating the usage of each Cordova API.</p>
        <p>Coupled with
          <em>Apache Cordova 4 Programming</em>, the two volumes deliver almost 800 pages of materials; providing
          the most complete coverage of Apache Cordova available anywhere.</p>
        <p>From the book's web site:</p>
        <p>Apache Cordova API Cookbook provides experienced mobile developers with details about how each Cordova
          API works and how to use the APIs in their own applications. Using Apache Cordova, mobile developers
          can write cross-platform mobile apps using standard HTML 5, JavaScript, and CSS, and then deploy
          those apps to every leading mobile platform with little or no re-coding. Coverage is included for
          all Cordova/PhoneGap 3 APIs.</p>
        <p>Instead of just showing short snippets of code to explain a particular API, the book is chock full of
          complete, contextual examples. Inside the book, you’ll find more than 30 complete Cordova applications;
          chapter content highlights how those applications look and work on Android, iOS, Windows and more.
          The sample applications provide developers with applications they can run on every Cordova supported
          mobile device platform to see exactly what the API does, how it works and what the limitations are
          on each platform.</p>
        <p>Through realistic "cookbook" example code, pioneering enterprise mobile developer John Wargo helps you
          master the Cordova APIs and understand how to use them in your Cordova applications.</p>
        <p>Topics include:</p>
        <ul>
          <li>Accelerometer, compass and geolocation</li>
          <li>Image, video, and audio capture, playback, and management</li>
          <li>Determining connection and device information</li>
          <li>Interacting with contacts</li>
          <li>Responding to application events</li>
          <li>Using the device file system</li>
          <li>Globalizing apps</li>
          <li>Using the InAppBrowser</li>
          <li>Notifications</li>
          <li>Custom splash screens</li>
          <li>and much, much more</li>
        </ul>
        <p>Special care has been taken to make the application code easily readable and digestible by the reader.
          Weighing in at about 300 pages, you will not find a more thorough coverage of the topic anywhere.
        </p>
        <p><a href="http://cordova4programming.com" target="_blank" rel="noreferrer">
          Visit the book's web site.
        </a>
          &nbsp;
          <a href="https://amzn.to/3jxlnJA" target="_blank" rel="noreferrer">
            Visit the Amazon page for this book.
          </a>
        </p>

        <a href="https://amzn.to/3jxleG2" target="_blank" rel="noreferrer"><Image src="/images/cover-ac3p-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h2>Apache Cordova 3 Programming</h2>
        <p>This book is a complete rewrite of <em>PhoneGap Essentials</em>, updated for Apache Cordova 3; the first release after the project was moved to the Apache Foundation and renamed to Cordova. This book dramatically expanded the depth of topic coverage from the previous edition, delivering more details on how to use the framework for cross-platform mobile app development. This book never went to print, it is only available as an ebook.</p>
        <p><a href="https://amzn.to/3jxleG2" target="_blank" rel="noreferrer">
          Visit the Amazon page for this book.
        </a>
        </p>

        <a href="https://amzn.to/3qB1cvP" target="_blank" rel="noreferrer"><Image src="/images/cover-pge-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h2>PhoneGap Essentials</h2>
        <p>This was my first book on Apache Cordova development. Its a long story, but Cordova was originally called PhoneGap, but then it was changed to Cordova when it joined the Apache foundation. PhoneGap then became Adobe's distribution of Apache Cordova. Anyway, this PhoneGap book dealt with PhoneGap 2, the version released before it became Cordova.</p>
        <p><strong>Note:</strong> This book is woefully outdated and should not be used for anything (unless you happen
          to be working with PhoneGap 2.0 for some bizarre reason).</p>
        <p>Something cool about this book is that it was translated and published in both Mandarin and Korean.</p>
        <a href="https://amzn.to/3qB1cvP" target="_blank" rel="noreferrer">
          Visit the Amazon page for this book.
        </a>
      </div>
    );
  }

}

export default Cordova;