import React from 'react';
import Image from 'react-bootstrap/Image'

import './pages.css';

class Domino extends React.Component {

  componentDidMount() {
    // console.log('Domino: componentDidMount()');
    document.title = 'Notes/Domino: John Wargo Books';
  }

  render() {
    return (
      <div className='container'>
        <Image src="/images/cover-view-anthology-160.png" style={{ paddingBottom: "10px" }}></Image>
        <h1>Mastering Mobile for Notes/Domino</h1>
        <p>For several years, I was a technical advisor for The View, a magazine for IBM Lotus Domino developers and administrators. During that period, I published 20 articles in the publication. The majority of my articles were on mobile development for IBM Lotus Domino and this anthology contains 16 of my articles out of a total of 22 in the book.</p>
        <p>In the article series, I wrote about all of the different ways a developer could mobilize IBM Lotus Domino application data. I also covered several generic topics like cross platform development and how to think like a mobile developer.</p>
        <p>The book contains my articles on the following topics:</p>
        <ul>
          <li>Prepare your Domino developer brain (and applications) for mobile</li>
          <li> Mobilize Domino applications for multiple mobile device platforms</li>
          <li>Build a mobile app using a variety of solutions, including:</li>
          <ul>
            <li>Adobe PhoneGap</li>
            <li>Apple Dashcode</li>
            <li>Appcelerator Titanium</li>
            <li>BlackBerry Widgets</li>
            <li>Kryos Velocity AppXtender</li>
            <li>Rhodes and RhoConnect</li>
            <li>Sencha Touch</li>
            <li>Teamstudio Unplugged</li>
          </ul>
        </ul>
        <p>The book also includes articles by <em>Julian Buss</em> and <em>Michael Butcher</em>.</p>
      </div>
    );
  }
}

export default Domino;