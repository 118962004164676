import React from 'react';
import Image from 'react-bootstrap/Image'

import './pages.css';

class Smartphone extends React.Component {

  componentDidMount() {
    // console.log('Smartphone: componentDidMount()');
    document.title = 'Apache Cordova: John Wargo Books';
  }

  render() {
    return (
      <div className='container'>
        <a href="https://amzn.to/3hluQ3Y" target="_blank" rel="noreferrer"><Image src="/images/cover-atrix-160.png" style={{ paddingBottom: "10px" }}></Image></a>
        <h1>My Motorola Atrix</h1>
        <p>For several years, I worked for a North American carrier, so I had access to many different models of smartphones. Because of this, I was well suited to perform a technical review for a book on a popular smartphone model. I didn't write this book, but I am listed in the credits.</p>
        <p>The book provides step-by-step instructions with callouts to Motorola Atrix 4G photos so that you can see exactly
          what to do</p>
        <p>Available to help when you run into Motorola Atrix 4G problems or limitations</p>
        <p>Delivers Tips and Notes to help you get the most from your Motorola Atrix 4G</p>
        <p>Full-color, step-by-step tasks walk you through getting and keeping your Motorola Atrix 4G working just the way
          you want. Learn how to:</p>
        <ul>
          <li>Get started fast!</li>
          <li>Make the most of Atrix 4G’s advanced hardware and new Android software</li>
          <li>Convert your Atrix 4G into a laptop with Motorola’s amazing Lapdock</li>
          <li>Manage all your contacts and email together--from Gmail, Exchange, Facebook, and beyond</li>
          <li>Play media, search YouTube, upload new videos--even display videos on TV</li>
          <li>Connect to Bluetooth devices, Wi-Fi networks, and secure VPNs</li>
          <li>Visit, bookmark, share, and track websites</li>
          <li>Use GPS to find local search results and businesses</li>
          <li>Track your calendar--including events stored on Google Calendar or Microsoft Exchange</li>
          <li>Capture, store, and share photos</li>
          <li>Send and receive text and multimedia messages</li>
          <li>Use Google Voice to cut calling costs and automatically transcribe voicemail</li>
          <li>Get instant information updates with real-time widgets</li>
          <li>Find, choose, install, and work with new Android apps</li>
          <li>Customize wallpaper, keyboard, display, and accessibility options</li>
          <li>Secure your Atrix with its built-in fingerprint reader</li>
          <li>Squeeze out more hours of battery life</li>
          <li>Keep your Motorola Atrix 4G up-to-date and running smoothly</li>
        </ul>
        <p>
          <a href="https://amzn.to/3hluQ3Y" target="_blank" rel="noreferrer">
            Visit the Amazon page for this book.
          </a>
        </p>

      </div>
    );
  }
}

export default Smartphone;